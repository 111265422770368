import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './CGU.css';  // Import the CSS file

const CGU = () => {
  return (
    <div>
      <Navbar />
      <div className="cgu-container">
        <h2>Nous contacter</h2>
        <h3>Adresse email</h3>
        <p>sav@techmaintsystems.fr</p>
        <h3>Numéro de téléphone</h3>
        <p>06 01 05 14 90</p>
      </div>
    </div>
  );
};

export default CGU;
